<template>
  <div class="bg-light vh-100">
    <b-row
      class="full-height"
      align-v="center"
      align-h="center"
    >
      <b-col
        md="6"
        lg="5"
      >
        <b-card class="py-2">
          <b-card-title
            title-tag="h1"
            class="mb-1 text-primary"
          >
            <div class="d-flex align-items-center justify-content-center mb-1">
              <b-img
                width="200px"
                fluid
                src="@/assets/images/logo/logo.png"
                alt="Login V2"
              />
            </div>
            Cambiar contraseña
          </b-card-title>
          <hr class="border-primary mb-1 mx-0 width-separator">
          <!-- form -->
          <validation-observer
            ref="forgotPasswordReset"
          >
            <b-form
              class="mt-2"
              @submit.prevent="handleSubmitResetPassword"
              @keydown.enter="handleSubmitResetPassword"
            >
              <b-row>
                <b-col>
                  <b-form-group
                    label="Nueva contraseña"
                    label-for="login-password"
                    class="text-secondary"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nueva contraseña"
                      rules="required|min:8"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label="Repita nueva contraseña"
                    label-for="login-confirmPassword"
                    class="text-secondary"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Repita nueva contraseña"
                      rules="required|min:8"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="login-confirmPassword"
                          v-model="confirmPassword"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <div>
                        <small class="text-danger">{{ passwordsDontMatchFormError }}</small>
                      </div>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row align-h="center">
                <b-col cols="auto">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-2 mr-1"
                  >
                    {{ 'Enviar' }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      token: this.$route.params.token,
      email: this.$route.params.email,
      password: '',
      confirmPassword: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordsDontMatchFormError() {
      if (this.password !== this.confirmPassword) {
        return 'Las contraseñas no coinciden'
      }

      return ''
    },
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword',
    }),
    handleSubmitResetPassword() {
      this.$refs.forgotPasswordReset.validate().then(success => {
        if (!success || this.password !== this.confirmPassword) {
          return
        }

        this.resetPassword({
          email: this.email,
          password: this.password,
          password_confirmation: this.confirmPassword,
          token: this.token,
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.width-separator {
  width: 87px;
}
</style>
